import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`In this lesson, we'll learn about estimating the probability of `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span></span></span></span></span>{`-grams and see how we can approximate the probability of a document using `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span></span></span></span></span>{`-gram probabilities.`}</p>
    <h1 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h1>
    <p>{`After completing this lesson, you'll be able to ...`}</p>
    <ul>
      <li parentName="ul">{`estimate the probability of an `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.4306em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`n`}</span></span></span></span></span>{`-gram`}</li>
      <li parentName="ul">{`estimate the probability of a document`}</li>
    </ul>
    <h1 {...{
      "id": "prerequisites",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#prerequisites",
        "aria-label": "prerequisites permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Prerequisites`}</h1>
    <p>{`Before you start, ensure you're ...`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/probability-basics"
        }}>{`familiar with the basics of probability`}</a></li>
    </ul>
    <h1 {...{
      "id": "background",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#background",
        "aria-label": "background permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Background`}</h1>
    <p>{`In the `}<a parentName="p" {...{
        "href": "/tutorials/probability-basics"
      }}>{`previous lesson`}</a>{`, we learned some of the basics of probability.  Now we'll look at how that can be applied to estimate the probability of some sequence (ex. a sentence, document, etc.).`}</p>
    <p>{`Estimating the probability of some sequence?  Why would we want to do such a thing?  Have you ever been frustrated by a strange autocorrect or autocomplete?  That was the result of a poor `}<strong parentName="p">{`language model`}</strong>{`.  A `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Language_model",
        "target": "_self",
        "rel": "nofollow"
      }}><strong parentName="a">{`language model`}</strong></a>{` is a probability distribution over sequences of words or characters estimated using large text corpora.  Sequence probabilities play an important role in a number of natural language processing tasks including machine translation, speech recognition, named entity recognition, and part of speech tagging.  `}</p>
    <p>{`Given their broad usage, let's take a look at how to estimate sequence probabilities...`}</p>
    <h1 {...{
      "id": "sequence-probabilities",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#sequence-probabilities",
        "aria-label": "sequence probabilities permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sequence probabilities`}</h1>
    <p>{`We can compute the probability of a sequence using the `}<strong parentName="p">{`chain rule`}</strong>{` which tells us that the joint probability of an arbitrary sequence of words is just a product of conditional probabilities:`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mn parentName="msub">{`1`}</mn></msub><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mn parentName="msub">{`2`}</mn></msub><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mn parentName="msub">{`3`}</mn></msub><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mo parentName="mrow">{`…`}</mo><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mi parentName="msub">{`n`}</mi></msub><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mn parentName="msub">{`1`}</mn></msub><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mn parentName="msub">{`2`}</mn></msub><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mn parentName="msub">{`1`}</mn></msub><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mn parentName="msub">{`3`}</mn></msub><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mn parentName="msub">{`1`}</mn></msub><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mn parentName="msub">{`2`}</mn></msub><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`…`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mi parentName="msub">{`n`}</mi></msub><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mn parentName="msub">{`1`}</mn></msub><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mn parentName="msub">{`2`}</mn></msub><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mn parentName="msub">{`3`}</mn></msub><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mo parentName="mrow">{`…`}</mo><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mrow parentName="msub"><mi parentName="mrow">{`n`}</mi><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow></msub><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`P(w_{1}, w_{2}, w_{3}, \\ldots, w_{n}) = P(w_{1})P(w_{2} \\vert w_{1})P(w_{3} \\vert w_{1}, w_{2})\\ldots P(w_{n} \\vert w_{1}, w_{2}, w_{3},\\ldots,w_{n-1})`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`3`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}>{`…`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.1514em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`n`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`3`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}>{`…`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.1514em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`n`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`3`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}>{`…`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`n`}</span><span parentName="span" {...{
                                "className": "mbin mtight"
                              }}>{`−`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.2083em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span></p>
    <p>{`Using a more concrete example ...`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`I’ve been kissed by a rose on the grey`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`=`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`P(\\text{I've been kissed by a rose on the grey}) =`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`I’ve been kissed by a rose on the grey`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span></span></span></span></span></p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`I’ve`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`been`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`I’ve`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`kissed`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`I’ve been`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`by`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`I’ve been kissed`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`P(\\text{I've}) P(\\text{been} \\vert \\text{I've}) P(\\text{kissed} \\vert \\text{I've been}) P(\\text{by} \\vert \\text{I've been kissed})`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`I’ve`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`been`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`I’ve`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`kissed`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`I’ve been`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`by`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`I’ve been kissed`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span>{` `}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`a`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`I’ve been kissed by`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`rose`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`I’ve been kissed by a`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`on`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`I’ve been kissed by a rose`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`P(\\text{a} \\vert \\text{I've been kissed by}) P(\\text{rose} \\vert \\text{I've been kissed by a}) P(\\text{on} \\vert \\text{I've been kissed by a rose})`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`a`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`I’ve been kissed by`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`rose`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`I’ve been kissed by a`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`on`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`I’ve been kissed by a rose`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span>{` `}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`the`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`I’ve been kissed by a rose on`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`grey`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`I’ve been kissed by a rose on the`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`P(\\text{the} \\vert \\text{I've been kissed by a rose on}) P(\\text{grey} \\vert \\text{I've been kissed by a rose on the})`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`the`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`I’ve been kissed by a rose on`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`grey`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`I’ve been kissed by a rose on the`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span>{` `}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "300px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/7fcaaa3257751f10b9d639a347b8948f/f93b5/seal-ii.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "99.32432432432432%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAYEAQX/xAAWAQEBAQAAAAAAAAAAAAAAAAACBAP/2gAMAwEAAhADEAAAAfQxa5iVWLOmXZM3NSItP//EABoQAAIDAQEAAAAAAAAAAAAAAAEDAAIRITP/2gAIAQEAAQUCw7R/Y8EoivK5xcUdV//EABkRAAEFAAAAAAAAAAAAAAAAAAABAhARIf/aAAgBAwEBPwFcKHx//8QAGREAAwEBAQAAAAAAAAAAAAAAAAECBDEh/9oACAECAQE/Ac6VSO/THxldP//EABwQAAICAgMAAAAAAAAAAAAAAAECABARUUFhcf/aAAgBAQAGPwLqMjNk8U4GqXyMRqlJ1P/EABoQAQADAQEBAAAAAAAAAAAAAAEAETEhEGH/2gAIAQEAAT8hKf1sYtc9XjFGaIeKhl8BH2Aki32PvEXP/9oADAMBAAIAAwAAABCU8L3/xAAYEQADAQEAAAAAAAAAAAAAAAAAATEQEf/aAAgBAwEBPxB+sWhQ/8QAGREBAAIDAAAAAAAAAAAAAAAAAQAQETFx/9oACAECAQE/EETMSKe3s//EAB0QAQADAQACAwAAAAAAAAAAAAEAESExQVFhkaH/2gAIAQEAAT8QIyNEqvlOJfjNmP1hAaYPrn3ELDHmS4C1qPNakV4FniJGV5fRDf2JlTx8hG0ddibW8vbU/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "The cover of Seal II ",
              "title": "Slightly more plausible if you first hear 'There used to be a greying tower alone on the sea ... 🎶'",
              "src": "/static/7fcaaa3257751f10b9d639a347b8948f/f93b5/seal-ii.jpg",
              "srcSet": ["/static/7fcaaa3257751f10b9d639a347b8948f/a80bd/seal-ii.jpg 148w", "/static/7fcaaa3257751f10b9d639a347b8948f/1c91a/seal-ii.jpg 295w", "/static/7fcaaa3257751f10b9d639a347b8948f/f93b5/seal-ii.jpg 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Slightly more plausible if you first hear 'There used to be a greying tower alone on the sea ... 🎶'`}</figcaption>{`
  `}</figure><sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup></p>
    <p>{`The chain rule is really just an extension of the definition of conditional probability and its relation to the joint probability.`}<sup parentName="p" {...{
        "id": "fnref-3"
      }}><a parentName="sup" {...{
          "href": "#fn-3",
          "className": "footnote-ref"
        }}>{`3`}</a></sup></p>
    <p>{`If we wanted to apply the chain rule to estimating the likelihood of a sentence, though, we'd have a hard time estimating the likelihood of many of these subsequences (there are an `}<em parentName="p">{`infinite`}</em>{` number of possible sentences).`}</p>
    <p>{`If we can't compute the probability of an arbitrarily long sequence directly, How can we at least approximate it?`}</p>
    <h3 {...{
      "id": "markov-assumption",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#markov-assumption",
        "aria-label": "markov assumption permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Markov assumption`}</h3>
    <p>{`One option is to make a powerful simplifying assumption that we can approximate a complete history using a more limited context (history):  `}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mn parentName="msub">{`1`}</mn></msub><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mn parentName="msub">{`2`}</mn></msub><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mn parentName="msub">{`3`}</mn></msub><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mo parentName="mrow">{`…`}</mo><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mi parentName="msub">{`n`}</mi></msub><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`≈`}</mo><msub parentName="mrow"><mo parentName="msub">{`∏`}</mo><mi parentName="msub">{`i`}</mi></msub><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mi parentName="msub">{`i`}</mi></msub><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mrow parentName="msub"><mi parentName="mrow">{`i`}</mi><mo parentName="mrow">{`−`}</mo><mi parentName="mrow">{`k`}</mi></mrow></msub><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mo parentName="mrow">{`…`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mrow parentName="msub"><mi parentName="mrow">{`i`}</mi><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow></msub><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`P(w_{1}, w_{2}, w_{3}, \\ldots, w_{n}) \\approx \\prod_{i} P(w_{i} \\vert w_{i-k}, \\ldots w_{i-1})`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`3`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}>{`…`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.1514em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`n`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`≈`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.0497em",
                  "verticalAlign": "-0.2997em"
                }
              }}></span><span parentName="span" {...{
                "className": "mop"
              }}><span parentName="span" {...{
                  "className": "mop op-symbol small-op",
                  "style": {
                    "position": "relative",
                    "top": "0em"
                  }
                }}>{`∏`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.162em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.4003em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.2997em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3117em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3361em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`i`}</span><span parentName="span" {...{
                                "className": "mbin mtight"
                              }}>{`−`}</span><span parentName="span" {...{
                                "className": "mord mathnormal mtight",
                                "style": {
                                  "marginRight": "0.03148em"
                                }
                              }}>{`k`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.2083em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}>{`…`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3117em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`i`}</span><span parentName="span" {...{
                                "className": "mbin mtight"
                              }}>{`−`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.2083em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span></p>
    <p>{`This simplification is known as the `}<strong parentName="p">{`Markov assumption`}</strong>{`.  `}</p>
    <p>{`For example, with `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`k`}</mi><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`2`}</mn></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`k=2`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03148em"
                }
              }}>{`k`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`2`}</span></span></span></span></span>{`, `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow">{`…`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\ldots`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.123em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}>{`…`}</span></span></span></span></span></p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`desert`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`the man in black fled across the`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`≈`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`desert`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`across the`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`P(\\text{desert} \\vert \\text{the man in black fled across the}) \\approx P(\\text{desert} \\vert \\text{across the})`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`desert`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`the man in black fled across the`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`≈`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`desert`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`across the`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span></p>
    <p>{`Language models that use this kind of simplifying assumption are known as Markov chains.  The size of the history window determines the `}<strong parentName="p">{`order`}</strong><sup parentName="p" {...{
        "id": "fnref-4"
      }}><a parentName="sup" {...{
          "href": "#fn-4",
          "className": "footnote-ref"
        }}>{`4`}</a></sup>{` of the Markov chain. `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`An order zero Markov chain makes no use of history and only ever considers the current word.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`An order 1 Markov chain makes use of the immediately preceding token (i.e., a bigram model).`}</p>
      </li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Order`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}><span parentName="th" {...{
              "className": "math math-inline"
            }}><span parentName="span" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-mathml"
                }}><math parentName="span" {...{
                    "xmlns": "http://www.w3.org/1998/Math/MathML"
                  }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                        "encoding": "application/x-tex"
                      }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "0.4306em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord mathnormal"
                    }}>{`n`}</span></span></span></span></span>{`-gram`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`unigram`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`bigram`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`trigram`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The higher the order of the model used, the rarer the `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span></span></span></span></span>{`-grams become.  `}</p>
    <p>{`To practice, let's write out what the probability calculation would be using different order Markov chains for the following sequence:`}</p>
    <blockquote>
      <p parentName="blockquote">{`the man in black fled across the desert`}</p>
    </blockquote>
    <p>{`To understand the extreme of this simplifying assumption, let's examine the Order 0 Markov model:`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`the man in black fled across the desert`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`≈`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`the`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`man`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`in`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`black`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`fled`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`across`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`the`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`desert`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`P(\\text{the man in black fled across the desert}) \\approx P(\\text{the})P(\\text{man})P(\\text{in})P(\\text{black})P(\\text{fled})P(\\text{across})P(\\text{the})P(\\text{desert})`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`the man in black fled across the desert`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`≈`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`the`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`man`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`in`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`black`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`fled`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`across`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`the`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`desert`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span></p>
    <p>{`One problem with this strong independence assumption is that ungrammatical sequences can end up with higher probabilities than grammatical sequences:`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`the`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`the`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`the`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`the`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`>`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`the`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`man`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`in`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`black`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`P(\\text{the})P(\\text{the})P(\\text{the})P(\\text{the}) > P(\\text{the})P(\\text{man})P(\\text{in})P(\\text{black})`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`the`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`the`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`the`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`the`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`>`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`the`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`man`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`in`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`black`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span></p>
    <p>{`Another issue with the lowest order model is that it is invariant to order:`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`the man in black`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`man black the in`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`P(\\text{the man in black}) = P(\\text{man black the in})`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`the man in black`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`man black the in`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span></p>
    <p>{`As a point of comparison, let's consider an Order 1 model:`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`the man in black fled across the desert`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`≈`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`man`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`the`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`in`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`man`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`black`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`in`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`fled`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`black`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`P(\\text{the man in black fled across the desert}) \\approx P(\\text{man} \\vert \\text{the}) P(\\text{in} \\vert \\text{man}) P(\\text{black} \\vert \\text{in}) P(\\text{fled} \\vert \\text{black})`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`the man in black fled across the desert`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`≈`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`man`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`the`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`in`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`man`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`black`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`in`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`fled`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`black`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span>{`
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`across`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`fled`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`the`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`across`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`desert`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`the`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`P(\\text{across} \\vert \\text{fled}) P(\\text{the} \\vert \\text{across}) P(\\text{desert} \\vert \\text{the})`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`across`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`fled`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`the`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`across`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`desert`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`the`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span></p>
    <p>{`While it's common to use a higher order Markov chain, an Order 1 model already addresses the two shortcomings we noted about the Order 0 model.`}</p>
    <h2 {...{
      "id": "estimating-n-gram-probabilities",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#estimating-n-gram-probabilities",
        "aria-label": "estimating n gram probabilities permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Estimating `}<span parentName="h2" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span></span></span></span></span>{`-gram probabilities`}</h2>
    <p>{`We can estimate `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span></span></span></span></span>{`-gram probabilities in the "real world" by counting occurrences in some collection of documents (a corpus):`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mi parentName="msub">{`n`}</mi></msub><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mrow parentName="msub"><mi parentName="mrow">{`n`}</mi><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow></msub><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mrow parentName="mfrac"><mi parentName="mrow">{`c`}</mi><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`(`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mrow parentName="msub"><mi parentName="mrow">{`n`}</mi><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow></msub><mo parentName="mrow" {...{
                        "separator": "true"
                      }}>{`,`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mi parentName="msub">{`n`}</mi></msub><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`)`}</mo></mrow><mrow parentName="mfrac"><mi parentName="mrow">{`c`}</mi><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`(`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mrow parentName="msub"><mi parentName="mrow">{`n`}</mi><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow></msub><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`)`}</mo></mrow></mfrac></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`P(w_{n} \\vert w_{n-1}) = \\frac{c(w_{n-1}, w_{n})}{c(w_{n-1})}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.1514em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`n`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`n`}</span><span parentName="span" {...{
                                "className": "mbin mtight"
                              }}>{`−`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.2083em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.53em",
                  "verticalAlign": "-0.52em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.01em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.655em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`c`}</span><span parentName="span" {...{
                                "className": "mopen mtight"
                              }}>{`(`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.02691em"
                                  }
                                }}>{`w`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3173em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.357em",
                                            "marginLeft": "-0.0269em",
                                            "marginRight": "0.0714em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.5em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size3 size1 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mathnormal mtight"
                                              }}>{`n`}</span><span parentName="span" {...{
                                                "className": "mbin mtight"
                                              }}>{`−`}</span><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.2025em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mclose mtight"
                              }}>{`)`}</span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.485em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`c`}</span><span parentName="span" {...{
                                "className": "mopen mtight"
                              }}>{`(`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.02691em"
                                  }
                                }}>{`w`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3173em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.357em",
                                            "marginLeft": "-0.0269em",
                                            "marginRight": "0.0714em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.5em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size3 size1 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mathnormal mtight"
                                              }}>{`n`}</span><span parentName="span" {...{
                                                "className": "mbin mtight"
                                              }}>{`−`}</span><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.2025em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mpunct mtight"
                              }}>{`,`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.02691em"
                                  }
                                }}>{`w`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.1645em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.357em",
                                            "marginLeft": "-0.0269em",
                                            "marginRight": "0.0714em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.5em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size3 size1 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mathnormal mtight"
                                              }}>{`n`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.143em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mclose mtight"
                              }}>{`)`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.52em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span></span></span></span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`docs `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"SOS"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"i"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"like"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"turtles"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"EOS"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"SOS"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"i"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"like"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"tortoises"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"EOS"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"SOS"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"some"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"turtles"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"are"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"tortoises"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"EOS"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"SOS"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"all"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"tortoises"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"are"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"turtles"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"EOS"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`First, let's count occurrences of each token across all documents:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Unigram`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TOTAL`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`SOS`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`EOS`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`all`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`are`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`i`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`like`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`some`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`tortoises`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`turtles`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TOTAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`22`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`... and now our bigrams:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}><code parentName="th" {...{
              "className": "language-text"
            }}>{`SOS`}</code></th>
          <th parentName="tr" {...{
            "align": null
          }}><code parentName="th" {...{
              "className": "language-text"
            }}>{`EOS`}</code></th>
          <th parentName="tr" {...{
            "align": null
          }}><code parentName="th" {...{
              "className": "language-text"
            }}>{`all`}</code></th>
          <th parentName="tr" {...{
            "align": null
          }}><code parentName="th" {...{
              "className": "language-text"
            }}>{`are`}</code></th>
          <th parentName="tr" {...{
            "align": null
          }}><code parentName="th" {...{
              "className": "language-text"
            }}>{`i`}</code></th>
          <th parentName="tr" {...{
            "align": null
          }}><code parentName="th" {...{
              "className": "language-text"
            }}>{`like`}</code></th>
          <th parentName="tr" {...{
            "align": null
          }}><code parentName="th" {...{
              "className": "language-text"
            }}>{`some`}</code></th>
          <th parentName="tr" {...{
            "align": null
          }}><code parentName="th" {...{
              "className": "language-text"
            }}>{`tortoises`}</code></th>
          <th parentName="tr" {...{
            "align": null
          }}><code parentName="th" {...{
              "className": "language-text"
            }}>{`turtles`}</code></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`SOS`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`EOS`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`all`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`are`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`i`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`like`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`some`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`tortoises`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`turtles`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The first column represents the first element in the bigram (`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mi parentName="msub">{`i`}</mi></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`w_{i}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.5806em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3117em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`), while the remaining columns represent the word word that immediately follows (`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mi parentName="msub">{`j`}</mi></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`w_{j}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.7167em",
                  "verticalAlign": "-0.2861em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3117em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight",
                                "style": {
                                  "marginRight": "0.05724em"
                                }
                              }}>{`j`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.2861em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`).  Cells with a value of 0 indicate the bigram wasn't observed in the data.  Using these two tables, we can calculate `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`i`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`SOS`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`P( \\text{i} \\vert \\text{SOS})`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`i`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`SOS`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`i`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`SOS`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mrow parentName="mfrac"><mi parentName="mrow">{`c`}</mi><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`(`}</mo><mtext parentName="mrow">{`SOS i`}</mtext><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`)`}</mo></mrow><mrow parentName="mfrac"><mi parentName="mrow">{`c`}</mi><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`(`}</mo><mtext parentName="mrow">{`SOS`}</mtext><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`)`}</mo></mrow></mfrac><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mn parentName="mfrac">{`2`}</mn><mn parentName="mfrac">{`4`}</mn></mfrac><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mn parentName="mfrac">{`1`}</mn><mn parentName="mfrac">{`2`}</mn></mfrac></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`P( \\text{i} \\vert \\text{SOS}) = \\frac{c( \\text{SOS i})}{c( \\text{SOS})} = \\frac{2}{4} = \\frac{1}{2}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`i`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`SOS`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.53em",
                  "verticalAlign": "-0.52em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.01em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.655em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`c`}</span><span parentName="span" {...{
                                "className": "mopen mtight"
                              }}>{`(`}</span><span parentName="span" {...{
                                "className": "mord text mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`SOS`}</span></span><span parentName="span" {...{
                                "className": "mclose mtight"
                              }}>{`)`}</span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.485em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`c`}</span><span parentName="span" {...{
                                "className": "mopen mtight"
                              }}>{`(`}</span><span parentName="span" {...{
                                "className": "mord text mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`SOS i`}</span></span><span parentName="span" {...{
                                "className": "mclose mtight"
                              }}>{`)`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.52em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.1901em",
                  "verticalAlign": "-0.345em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8451em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.655em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`4`}</span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.394em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.345em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.1901em",
                  "verticalAlign": "-0.345em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8451em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.655em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.394em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.345em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span></span></span></span></p>
    <p>{`Notice we can also calculate this using only the second table by normalizing the count in a cell using the sum of its row ...`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mi parentName="msub">{`j`}</mi></msub><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mi parentName="msub">{`i`}</mi></msub><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mrow parentName="mfrac"><mi parentName="mrow">{`c`}</mi><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`(`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mi parentName="msub">{`i`}</mi></msub><mo parentName="mrow" {...{
                        "separator": "true"
                      }}>{`,`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mi parentName="msub">{`j`}</mi></msub><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`)`}</mo></mrow><mrow parentName="mfrac"><msub parentName="mrow"><mo parentName="msub">{`∑`}</mo><mi parentName="msub">{`k`}</mi></msub><mi parentName="mrow">{`c`}</mi><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`(`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mi parentName="msub">{`i`}</mi></msub><mo parentName="mrow" {...{
                        "separator": "true"
                      }}>{`,`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mi parentName="msub">{`k`}</mi></msub><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`)`}</mo></mrow></mfrac></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`P(w_{j} \\vert w_{i}) = \\frac{c(w_{i}, w_{j})}{\\sum_{k} c(w_{i}, w_{k})}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.0361em",
                  "verticalAlign": "-0.2861em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3117em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight",
                                "style": {
                                  "marginRight": "0.05724em"
                                }
                              }}>{`j`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.2861em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.02691em"
                  }
                }}>{`w`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3117em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0269em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.6023em",
                  "verticalAlign": "-0.57em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.0323em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.655em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mop mtight"
                              }}><span parentName="span" {...{
                                  "className": "mop op-symbol small-op mtight",
                                  "style": {
                                    "position": "relative",
                                    "top": "0em"
                                  }
                                }}>{`∑`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.1746em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.1786em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.0714em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.5em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size3 size1 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mathnormal mtight",
                                                "style": {
                                                  "marginRight": "0.03148em"
                                                }
                                              }}>{`k`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3214em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mspace mtight",
                                "style": {
                                  "marginRight": "0.1952em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`c`}</span><span parentName="span" {...{
                                "className": "mopen mtight"
                              }}>{`(`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.02691em"
                                  }
                                }}>{`w`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3281em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.357em",
                                            "marginLeft": "-0.0269em",
                                            "marginRight": "0.0714em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.5em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size3 size1 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mathnormal mtight"
                                              }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.143em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mpunct mtight"
                              }}>{`,`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.02691em"
                                  }
                                }}>{`w`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3448em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.3488em",
                                            "marginLeft": "-0.0269em",
                                            "marginRight": "0.0714em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.5em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size3 size1 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mathnormal mtight",
                                                "style": {
                                                  "marginRight": "0.03148em"
                                                }
                                              }}>{`k`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.1512em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mclose mtight"
                              }}>{`)`}</span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.5073em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`c`}</span><span parentName="span" {...{
                                "className": "mopen mtight"
                              }}>{`(`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.02691em"
                                  }
                                }}>{`w`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3281em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.357em",
                                            "marginLeft": "-0.0269em",
                                            "marginRight": "0.0714em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.5em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size3 size1 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mathnormal mtight"
                                              }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.143em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mpunct mtight"
                              }}>{`,`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.02691em"
                                  }
                                }}>{`w`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3281em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.357em",
                                            "marginLeft": "-0.0269em",
                                            "marginRight": "0.0714em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.5em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size3 size1 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mathnormal mtight",
                                                "style": {
                                                  "marginRight": "0.05724em"
                                                }
                                              }}>{`j`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.2819em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mclose mtight"
                              }}>{`)`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.57em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span></span></span></span></p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`i`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`SOS`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mrow parentName="mfrac"><mi parentName="mrow">{`c`}</mi><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`(`}</mo><mtext parentName="mrow">{`SOS i`}</mtext><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`)`}</mo></mrow><mrow parentName="mfrac"><msub parentName="mrow"><mo parentName="msub">{`∑`}</mo><mi parentName="msub">{`k`}</mi></msub><mi parentName="mrow">{`c`}</mi><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`(`}</mo><mtext parentName="mrow">{`SOS`}</mtext><mo parentName="mrow" {...{
                        "separator": "true"
                      }}>{`,`}</mo><msub parentName="mrow"><mi parentName="msub">{`w`}</mi><mi parentName="msub">{`k`}</mi></msub><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`)`}</mo></mrow></mfrac></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`P(\\text{i} \\vert \\text{SOS}) = \\frac{c(\\text{SOS i})}{\\sum_{k} c(\\text{SOS}, w_{k})}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`i`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`SOS`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.58em",
                  "verticalAlign": "-0.57em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.01em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.655em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mop mtight"
                              }}><span parentName="span" {...{
                                  "className": "mop op-symbol small-op mtight",
                                  "style": {
                                    "position": "relative",
                                    "top": "0em"
                                  }
                                }}>{`∑`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.1746em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.1786em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.0714em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.5em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size3 size1 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mathnormal mtight",
                                                "style": {
                                                  "marginRight": "0.03148em"
                                                }
                                              }}>{`k`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3214em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mspace mtight",
                                "style": {
                                  "marginRight": "0.1952em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`c`}</span><span parentName="span" {...{
                                "className": "mopen mtight"
                              }}>{`(`}</span><span parentName="span" {...{
                                "className": "mord text mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`SOS`}</span></span><span parentName="span" {...{
                                "className": "mpunct mtight"
                              }}>{`,`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight",
                                  "style": {
                                    "marginRight": "0.02691em"
                                  }
                                }}>{`w`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3448em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.3488em",
                                            "marginLeft": "-0.0269em",
                                            "marginRight": "0.0714em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.5em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size3 size1 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mathnormal mtight",
                                                "style": {
                                                  "marginRight": "0.03148em"
                                                }
                                              }}>{`k`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.1512em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mclose mtight"
                              }}>{`)`}</span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.485em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`c`}</span><span parentName="span" {...{
                                "className": "mopen mtight"
                              }}>{`(`}</span><span parentName="span" {...{
                                "className": "mord text mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`SOS i`}</span></span><span parentName="span" {...{
                                "className": "mclose mtight"
                              }}>{`)`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.57em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span></span></span></span></p>
    <p>{`Think for a moment why that is the case.  `}</p>
    <p>{`The row represents the counts of `}<em parentName="p">{`all`}</em>{` of the `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span></span></span></span></span>{`-grams (bigrams) in which the given term (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`SOS`}</code>{`) appears as the first element.`}</p>
    <h1 {...{
      "id": "issues",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#issues",
        "aria-label": "issues permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Issues`}</h1>
    <p>{`Even with the simplifying assumption, we still run into a number of problems.`}</p>
    <h2 {...{
      "id": "zero-values",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#zero-values",
        "aria-label": "zero values permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Zero values`}</h2>
    <p>{`However large the corpus we use to estimate our sequence probabilities, we'll never be able to include `}<em parentName="p">{`all`}</em>{` words.  How do we handle cases of unseen words?  Consider an Order 0 Markov chain.  Assigning a zero probability to any term in the sequence will mean the probability of the entire sequence becomes 0.  How can we accomodate an open vocabulary?  One option is to replace all infrequent tokens below some threshold (ex. `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow">{`<`}</mo><mn parentName="mrow">{`2`}</mn></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`< 2`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.5782em",
                  "verticalAlign": "-0.0391em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`2`}</span></span></span></span></span>{`) with special symbol such as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<UNK>`}</code>{` to represent unseen/unknown words.  If we tally our counts, we'll come with a non-zero probability for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<UNK>`}</code>{` which we can substitute in when we attempt to estimate the probability of an unknown word.`}</p>
    <h2 {...{
      "id": "sparsity",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#sparsity",
        "aria-label": "sparsity permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sparsity`}</h2>
    <p>{`The higher the order the language model, the richer the contextual information that can be represented. `}</p>
    <p>{`But there is a catch: as you increase the length of the `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span></span></span></span></span>{`-gram, you encounter fewer occurrences.  In fact a large number of grammatically correct `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span></span></span></span></span>{`-grams will never be encountered and thus indvertently assigned a value of 0.`}</p>
    <h3 {...{
      "id": "smoothing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#smoothing",
        "aria-label": "smoothing permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Smoothing`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.aclweb.org/anthology/P96-1041",
        "target": "_self",
        "rel": "nofollow"
      }}>{`There are many ways of addressing unreliable counts and zero values for higher order `}<span parentName="a" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.4306em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`n`}</span></span></span></span></span>{`-grams`}</a>{` by `}<strong parentName="p">{`smoothing`}</strong>{` or making adjustments to a probability distribution to improve reliability of estimates and avoid zeros.`}</p>
    <p>{`One alternative to smoothing that works better for language modeling is a form of `}<strong parentName="p">{`interpolation`}</strong>{` where we represent `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span></span></span></span></span>{`-gram probabilities as a linearly interpolated sum of component probabilities.  `}<a parentName="p" {...{
        "href": "https://parsertongue.org/readings/slp3/3.pdf#page=15",
        "target": "_self",
        "rel": "nofollow"
      }}>{`See Section 3.4.3 from the 3rd edition of Jurafsky and Martin's `}<em parentName="a">{`Speech and Language Processing`}</em>{` for an overview of this technique`}</a>{`. `}</p>
    {
      /* Essentially
      Most higher order $n$-grams are exceeding rare and so their counts aren't really reliable.  Lower order $n$-grams are more frequent, but they are less informative.  To balance these two properties we can decompose the probability of $n$-grams into a linear interpolated sum of its component probabilities:
          $P(\text{orangutan} \vert \text{the green}) \approx \lambda_{1}P(\text{orangutan} \vert \text{the green}) + \lambda_{2}P(\text{orangutan} \vert \text{green}) + \lambda_{3}P(\text{orangutan})$ */
    }
    <h2 {...{
      "id": "vanishing-probabilities",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#vanishing-probabilities",
        "aria-label": "vanishing probabilities permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Vanishing probabilities`}</h2>
    <p>{`Probabilities range from 0 to 1.  This means a product of probabilities approaches zero as the number of terms in the product increases.`}</p>
    <p>{`When representing increasingly small values, computers are subject to `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Arithmetic_underflow",
        "target": "_self",
        "rel": "nofollow"
      }}>{`arithmetic underflow`}</a>{`: at some point the computer can no longer distinguish between a very small number and zero.  If we're estimating sequence probabilities, we're likely to end up with many false zeros due to underflow.  The trick for avoiding this is to just convert all probabilities to log probabilities by taking the `}<strong parentName="p">{`log`}</strong>{` (using any base we like) and then summing in place of multiplication.`}</p>
    {
      /* !["A different kind of log"](images/log-lady.png ""My log has something to say"") [^log-lady-url] 
      [^log-lady-url]: [Image from Fandom's Twin Peaks wiki](https://twinpeaks.fandom.com/wiki/Margaret_Lanterman) */
    }
    {
      /* !["Better than bad"](images/ren-and-stimpy-log.png "Better than bad, but still the wrong log...") [^ren-and-stimpy-log-url]  
      [^ren-and-stimpy-log-url]: [Image from Fandom's Ren & Stimpy wiki](https://renandstimpy.fandom.com/wiki/Log) */
    }
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "317px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/95dd249a344ced00782db9ab47b3bfa6/5b4e9/john-napier.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "126.35135135135134%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAZABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAeJZJZVFF1osBon/xAAbEAADAAIDAAAAAAAAAAAAAAAAAQIQERITIv/aAAgBAQABBQI1PXopPCLps5+Bix//xAAVEQEBAAAAAAAAAAAAAAAAAAAQQf/aAAgBAwEBPwEp/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAGhABAAEFAAAAAAAAAAAAAAAAESAAARASYf/aAAgBAQAGPwKu4QhqWj//xAAcEAEAAgMAAwAAAAAAAAAAAAABESEAEDEgQWH/2gAIAQEAAT8hOP3Kbwts6iJsPNKsgJcKKXuDvi//2gAMAwEAAgADAAAAEMvJgv/EABYRAQEBAAAAAAAAAAAAAAAAABEAAf/aAAgBAwEBPxBJyd2F/8QAFxEAAwEAAAAAAAAAAAAAAAAAABEhEP/aAAgBAgEBPxBMopn/xAAcEAEAAgMBAQEAAAAAAAAAAAABABEhMXFRQWH/2gAIAQEAAT8QVBw4g1i0swT4B7H9GZYAexQ8n2U0uOh2orQi3gL9XcvwqbHJq9jP/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "\"John Napier\"",
              "title": "Jumping John Napier!  Naturally, we must be talking logarithms.",
              "src": "/static/95dd249a344ced00782db9ab47b3bfa6/5b4e9/john-napier.jpg",
              "srcSet": ["/static/95dd249a344ced00782db9ab47b3bfa6/a80bd/john-napier.jpg 148w", "/static/95dd249a344ced00782db9ab47b3bfa6/1c91a/john-napier.jpg 295w", "/static/95dd249a344ced00782db9ab47b3bfa6/5b4e9/john-napier.jpg 317w"],
              "sizes": "(max-width: 317px) 100vw, 317px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Jumping John Napier!  Naturally, we must be talking logarithms.`}</figcaption>{`
  `}</figure>{` `}<sup parentName="p" {...{
        "id": "fnref-5"
      }}><a parentName="sup" {...{
          "href": "#fn-5",
          "className": "footnote-ref"
        }}>{`5`}</a></sup>{`  `}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mn parentName="mrow">{`1`}</mn><msup parentName="mrow"><mn parentName="msup">{`0`}</mn><mrow parentName="msup"><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`?`}</mo><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`?`}</mo></mrow></msup><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`x`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`10^{??} = x`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8491em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`1`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`0`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8491em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.063em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mclose mtight"
                              }}>{`??`}</span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span></span></span></span></span></p>
    <p>{`If we convert our probabilities to log probabilities, we need to use `}<strong parentName="p">{`sums`}</strong>{` instead of products: `}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow">{`a`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow">{`b`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`l`}</mi><mi parentName="mrow">{`o`}</mi><mi parentName="mrow">{`g`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow">{`a`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`+`}</mo><mi parentName="mrow">{`l`}</mi><mi parentName="mrow">{`o`}</mi><mi parentName="mrow">{`g`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow">{`b`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`P(a) P(b) = log(P(a)) + log(P(b))`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.01968em"
                }
              }}>{`l`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`o`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`))`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`+`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.01968em"
                }
              }}>{`l`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`o`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`))`}</span></span></span></span></span></p>
    <p>{`Let's demonstrate this with code:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` math `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` log`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` exp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` isclose
p_a  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`.1`}</span>{`
p_b  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`.4`}</span>{`
prod `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` p_a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` p_b

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# convert to logspace, sum, and `}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# exponentiate to leave log space`}</span>{`
log_base `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span>{`
log_sum `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` log_base `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`**`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`log`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`p_a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` log_base`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` log`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`p_b`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` log_base`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# because of precision limitations,`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# these numbers won't be identical`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# use isclose to test equality`}</span>{`
isclose`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`prod`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` log_sum`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Using sums of log probabilities gives us far more `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Numerical_stability",
        "target": "_self",
        "rel": "nofollow"
      }}>{`numerical stability`}</a>{` than we had when dealing with vanishingly small products of probability chains.`}</p>
    <h1 {...{
      "id": "next-steps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#next-steps",
        "aria-label": "next steps permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next steps`}</h1>
    <p>{`You now know how to estimate the probability of an `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span></span></span></span></span>{`-gram and use such probabilities to estimate the likelihood of longer sequences (ex. sentences).  Let's practice ...`}</p>
    <h2 {...{
      "id": "practice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#practice",
        "aria-label": "practice permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Practice`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`If `}<span parentName="p" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`(`}</mo><mtext parentName="mrow">{`snorkel`}</mtext><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∣`}</mi><mtext parentName="mrow">{`mayonnaise`}</mtext><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`)`}</mo><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`P`}</mi><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`(`}</mo><mtext parentName="mrow">{`snorkel`}</mtext><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`P(\\text{snorkel} \\vert \\text{mayonnaise}) = P(\\text{snorkel})`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "1em",
                      "verticalAlign": "-0.25em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.13889em"
                    }
                  }}>{`P`}</span><span parentName="span" {...{
                    "className": "mopen"
                  }}>{`(`}</span><span parentName="span" {...{
                    "className": "mord text"
                  }}><span parentName="span" {...{
                      "className": "mord"
                    }}>{`snorkel`}</span></span><span parentName="span" {...{
                    "className": "mord"
                  }}>{`∣`}</span><span parentName="span" {...{
                    "className": "mord text"
                  }}><span parentName="span" {...{
                      "className": "mord"
                    }}>{`mayonnaise`}</span></span><span parentName="span" {...{
                    "className": "mclose"
                  }}>{`)`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.2778em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mrel"
                  }}>{`=`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.2778em"
                    }
                  }}></span></span><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "1em",
                      "verticalAlign": "-0.25em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.13889em"
                    }
                  }}>{`P`}</span><span parentName="span" {...{
                    "className": "mopen"
                  }}>{`(`}</span><span parentName="span" {...{
                    "className": "mord text"
                  }}><span parentName="span" {...{
                      "className": "mord"
                    }}>{`snorkel`}</span></span><span parentName="span" {...{
                    "className": "mclose"
                  }}>{`)`}</span></span></span></span></span>{`, what does this tell us about `}<span parentName="p" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`s`}</mi><mi parentName="mrow">{`n`}</mi><mi parentName="mrow">{`o`}</mi><mi parentName="mrow">{`r`}</mi><mi parentName="mrow">{`k`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`l`}</mi></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`snorkel`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.6944em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`s`}</span><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`n`}</span><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.02778em"
                    }
                  }}>{`or`}</span><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.03148em"
                    }
                  }}>{`k`}</span><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`e`}</span><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.01968em"
                    }
                  }}>{`l`}</span></span></span></span></span>{` and `}<span parentName="p" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`m`}</mi><mi parentName="mrow">{`a`}</mi><mi parentName="mrow">{`y`}</mi><mi parentName="mrow">{`o`}</mi><mi parentName="mrow">{`n`}</mi><mi parentName="mrow">{`n`}</mi><mi parentName="mrow">{`a`}</mi><mi parentName="mrow">{`i`}</mi><mi parentName="mrow">{`s`}</mi><mi parentName="mrow">{`e`}</mi></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`mayonnaise`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.854em",
                      "verticalAlign": "-0.1944em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`ma`}</span><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`yo`}</span><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`nnai`}</span><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`se`}</span></span></span></span></span>{`?`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`What types of tokens are more frequent than others?`}</p>
      </li>
    </ul>
    <h1 {...{
      "id": "related-resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#related-resources",
        "aria-label": "related resources permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Related resources`}</h1>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://public.parsertongue.org/readings/slp3/3.pdf#page=1",
            "target": "_self",
            "rel": "nofollow"
          }}>{`Sections 3.0-3.6 of Jurafsky and Martin's `}<em parentName="a">{`Speech and Language Processing`}</em>{` (3rd edition)`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.aclweb.org/anthology/P96-1041",
            "target": "_self",
            "rel": "nofollow"
          }}>{`https://www.aclweb.org/anthology/P96-1041`}</a></p>
      </li>
    </ul>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>{`Do you remember these lyrics differently ("grave" instead of "grey")?  This is a case of a `}<a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Mondegreen",
            "target": "_self",
            "rel": "nofollow"
          }}>{`Mondegreen`}</a>{`.  `}<a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-1"
        }}><a parentName="li" {...{
            "href": "https://upload.wikimedia.org/wikipedia/en/5/56/Seal_-_Kiss_From_A_Rose_%28single%29.jpg",
            "target": "_self",
            "rel": "nofollow"
          }}>{`Image from Wikipedia`}</a><a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-3"
        }}>{`If you don't remember how conditional probability relates to joint probability, take a look at `}<a parentName="li" {...{
            "href": "/tutorials/probability-basics"
          }}>{`this tutorial`}</a>{`.`}<a parentName="li" {...{
            "href": "#fnref-3",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-4"
        }}>{`This context window is also known as a (limited) `}<em parentName="li">{`horizon`}</em>{`.`}<a parentName="li" {...{
            "href": "#fnref-4",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-5"
        }}><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/John_Napier",
            "target": "_self",
            "rel": "nofollow"
          }}>{`Image from Wikipedia`}</a>{`
If it's been awhile since you've thought about logs, recall that `}<span parentName="li" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><mtext parentName="mrow">{`log`}</mtext><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`(`}</mo><mi parentName="mrow">{`x`}</mi><mo parentName="mrow" {...{
                        "separator": "true"
                      }}>{`,`}</mo><mtext parentName="mrow">{`base`}</mtext><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`10`}</mn><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`\\text{log}(x, \\text{base}=10)`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "1em",
                      "verticalAlign": "-0.25em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord text"
                  }}><span parentName="span" {...{
                      "className": "mord"
                    }}>{`log`}</span></span><span parentName="span" {...{
                    "className": "mopen"
                  }}>{`(`}</span><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`x`}</span><span parentName="span" {...{
                    "className": "mpunct"
                  }}>{`,`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.1667em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord text"
                  }}><span parentName="span" {...{
                      "className": "mord"
                    }}>{`base`}</span></span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.2778em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mrel"
                  }}>{`=`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.2778em"
                    }
                  }}></span></span><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "1em",
                      "verticalAlign": "-0.25em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord"
                  }}>{`10`}</span><span parentName="span" {...{
                    "className": "mclose"
                  }}>{`)`}</span></span></span></span></span>{` just gives us the power we need to raise some base to get `}<span parentName="li" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`x`}</mi></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`x`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.4306em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`x`}</span></span></span></span></span>{`:`}<a parentName="li" {...{
            "href": "#fnref-5",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      